@import url(https://fonts.googleapis.com/css?family=Open+Sans&display=swap);
/* using this file to style App.js */
/* styling the body */
html, body {
    margin: 0;
	padding: 0;
    font-family: 'Open Sans', sans-serif;
}
/* makes the main menu and the Userpage horizontally aligned */
#root {
    display: -webkit-flex;
    display: flex;
}
/* makes the background of the user list white */
#root > main {
    background: #f1f4f6;
    -webkit-flex: 1 1;
            flex: 1 1;
    border: solid 1px #f1f4f6;
}
/* styles the section that holds the user list */
#root > main > section {
    border: solid 1px #dddddd;
    padding-bottom: 2%;
}
/* styles the div that contains the table */
#root > main > div {
    display: -webkit-flex;
    display: flex;
}
/* styles the table */
#root > main > div > table {
    -webkit-flex: 1 1;
            flex: 1 1;
    margin: 0 30px;
    background: white;
    border-radius: 0 0 10px 10px;
}

/* styling the main admin console naviation on the left*/
#mainNav {
    position: sticky;
    position: -webkit-sticky;
    top: 0; /* required */
    padding-top: 100px;
    height: 100vh;
    /* width: 300px; */
    text-align: left;
    display: block;
    list-style-type: none;
    margin: 0;
    background: white;
}
/* styles the main navigation item */
.mainItem {
    position: relative;
    /* color: #22397a; */
    color: #bdc6dd;
    padding: 10px 0 10px 25px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.35;
    cursor: pointer;
}
/* styles the selected item of the main navigation */
.selectedItem {
    /* background: #036aa4; */
    color: #22397a
}
/* selected items icon (main left side bar) */
.selectedItem > img {
    -webkit-filter: invert(17%) sepia(48%) saturate(2009%) hue-rotate(203deg) brightness(98%) contrast(92%) !important;
            filter: invert(17%) sepia(48%) saturate(2009%) hue-rotate(203deg) brightness(98%) contrast(92%) !important;
}

#logoutButton {
    position: fixed;
    bottom: 50px;
    color: #22397a;
}
#logoutButton > img {
    -webkit-filter: invert(17%) sepia(48%) saturate(2009%) hue-rotate(203deg) brightness(98%) contrast(92%) !important;
            filter: invert(17%) sepia(48%) saturate(2009%) hue-rotate(203deg) brightness(98%) contrast(92%) !important;
}
/* styles the icons in the main navigation */
.mainItem > img {
    margin-bottom: -6px;
    -webkit-filter: invert(88%) sepia(10%) saturate(518%) hue-rotate(189deg) brightness(88%) contrast(97%);
            filter: invert(88%) sepia(10%) saturate(518%) hue-rotate(189deg) brightness(88%) contrast(97%);
    /* filter: invert(17%) sepia(48%) saturate(2009%) hue-rotate(203deg) brightness(98%) contrast(92%); */
}
/* styles the li tag that contains the backArrow */
#backArrow {
    position: absolute;
    top: 27px;
    left: 24px;
    cursor: pointer;
    z-index: 2;
}

#toggleMenuArrow {
    padding: 5px;
    position: fixed;
    top: 50px;
	border-radius: 50%;
    background: grey;
    z-index: 2;
    cursor: pointer;
    border: 3px solid white;
}
#signInBackGround {
    width: 100%;
    height: 100%;
    background: #403F44;
    position: fixed;
}
#signInBorder {
    border: solid 1px rgba(29, 29, 29, 0.25);
    border-radius: 3px;
    box-shadow: 3px 5px 10px 0 rgba(0, 0, 0, 0.15);
    margin: 150px auto;
    width: 445px;
    height: 470px;
}
#chatMessagesIcon {
    position: fixed;
    right: 50px;
    bottom: 50px;
    height: 60px;
    cursor: pointer;
}

#chatBackArrow {
    position: absolute;
    left: 8px;
    background: grey;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    height: 24px;
    padding: 2px;
}
.forgot_pass{
    color:white;
}
.forgot_pass:hover{
    color:white;
    text-decoration: underline;
    cursor: pointer;
}
.hidden{
    display: none;
}
/* every even column has a light grey background */
tbody > tr:nth-child(even) {
    background: #f7f8f9;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul{
  display: block
}

/* Button */
.btn {
    width: 140px;
    margin: 0 10px 0 0;
    padding: 1em 2em;
    color: #fff;
    background: linear-gradient(to right, #233169, #21418c) !important;
    border: 0;
    border-radius: 10px;
    font-size: 13px;
    cursor: pointer;
}
/* controls the buttons inside the Modal */
.btn--flex {
    display: -webkit-flex;
    display: flex;
    height: 35px;
    width: 290px;
    font-size: 20px;
}
/* styles the cancel button */
.btn--close {
    border-radius: 10px;
    background: rgb(53, 116, 174);
    cursor: pointer;
}
/* styles the confirm button*/
.btn--confirm {
    border-radius: 10px;
    background: rgb(53, 116, 174);
    cursor: pointer;
}

.dialog__btn {
    display: block;
    width: 100%;
    padding-bottom: 2em;
    padding-top: .7em;
}
h2 {
    font-weight: normal;
    font-size: 40px;
    text-align: left;
    margin-bottom: 20px;
}
label {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #1d1d1d;
}
/* inputs for Add User dialog */
input {
    outline: 0;
    border-width: 0 0 1px;
    border-color: black;
    font-size: 20px;
    width: 545px;
    margin-bottom: 25px;
}
/* changes the color of the placeholder for the inputs */
::-webkit-input-placeholder {
    color: #C9C9C9;
}
:-ms-input-placeholder {
    color: #C9C9C9;
}
::placeholder {
    color: #C9C9C9;
}
/* controls the input for the admin level options */
select {
    font-size: 15px;
    margin-bottom: 10px;
    width: 545px;
}
/* controls the input for the profile picture */
.inputProfile {
    text-align: center;
    position: relative;
    display: inline-block;
    width: 100px;
    height: 100px;
    border: solid 0.5px #cccccc;
    margin-top: 5px;
    margin-bottom: 17px;
}
.inputProfile input {
    opacity: 0;
    width: 0;
    height: 0;
}
.inputProfile img {
    margin-top: 12.5px;
    height: 75px;
    width: 75px;
}
/* In addfileDialog.js -> create new Subfolder */
/* styles the button that creates a new subfolder */
#CreateNewSub {
    padding: 1em 2em;
    border-radius: 10px;
    background: rgb(53, 116, 174);
    color: white;
    margin-bottom: 10px;
    font-size: 13px;
}
/* styles the select below the create new subfolder */
#selectSubfolder {
    margin-bottom: 20px;
}

/* styles the add button on the addPlanDialog.js */
.addItemPlan {
    text-align: center;
    font-size: 25px;
    font-weight: bolder;
    border-radius: 3px;
    border: solid 3px #147bb5;
    color: #147bb5;
    margin: 0;
    padding: 0 10px 2px 10px;
    cursor: pointer;
}
.addItemText {
    position: relative;
    top: -3px;
    margin-left: 12px;
}
/********** styles the selected users in create Plan page */
#selectedUsersList {
    display: block;
    width: 350px;
    margin: 20px 0;
}
/* styles the pencil icon to the right of the user name */
.selectedUserPencilIcon {
    position: absolute;
    padding-top: 8px;
    left: 350px;
    cursor: pointer;
    -webkit-filter: invert(17%) sepia(48%) saturate(2009%) hue-rotate(203deg) brightness(98%) contrast(92%) !important;
            filter: invert(17%) sepia(48%) saturate(2009%) hue-rotate(203deg) brightness(98%) contrast(92%) !important;
}
/* styles the minus sign to the right of the title/role */
.selectedUserMinusSign {
    position: absolute;
    left: 405px;
    font-size: 32px;
    color: red;
    font-weight: bolder;
    border-radius: 3px;
    border: solid 3px red;
    line-height: 13px;
    padding: 0 5px;
    height: 20px;
    cursor: pointer;
}
/* for the task list, moved the edit icon sign to the right so
 * it does not overlap with the minus sign */
.taskPencil {
    left: 377px;
}
.taskMinusSign {
    margin-top: 5px;
}
/* adding some margin between all the users */
#selectedUsersList li {
    margin-bottom: 20px;
}
/*************** TITLE list that is displayed in the ERP page */
#titleList {
    display: block;
    width: 350px;
    margin: 20px 0;
}
/* added some margin below the task item */
#titleList > li {
    margin-bottom: 20px;
}

/*************** new task list that is displayed in the ERP page */
#newTasksList {
    display: block;
    width: 350px;
    margin: 20px 0;
}
/* added some margin below the task item */
#newTasksList > li {
    margin-bottom: 20px;
}
/* On edit user title in ERP, styles the UL that stores the roles */
#userRoleList {
    display: block;
    width: 350px;
    height: 150px;
}
/* styles the list item which is a checkbox/username */
#userRoleList li {
    display: -webkit-flex;
    display: flex;
}
/* styles the checkbox */
#userRoleList li div {
    margin: 0 10px 10px 0;
}
/* edit the users title for the ERP */
#editTitle {
    width: 325px;
}
/* controls the size of the dropdown for user role */
#selectUserTitle {
    width: 325px;
}
/*************** Edit Plan Box UserList *************/
#editSelectedUsersList {
    display: block;
    width: 350px;
    margin: 20px 0;
}

/* adding some margin between all the users */
#editSelectedUsersList li {
    margin-bottom: 20px;
}
/* new task list that is displayed in the ERP page */
#editTasksList {
    display: block;
    width: 350px;
    margin: 20px 0;
}
/* added some margin below the task item */
#editTasksList > li {
    margin-bottom: 20px;
}
/* title list that is displayed in the ERP page */
#editTitleList {
    display: block;
    width: 350px;
    margin: 20px 0;
}
/* added some margin below the title item */
#editTitleList > li {
    margin-bottom: 20px;
}
/********************** EDIT ERP Page ********/
/* role list this is displayed in the edit ERP page */
#editUserRoleList {
    display: block;
    width: 350px;
    height: 150px;
}
/* styles the list item which is a checkbox/username */
#editUserRoleList li {
    display: -webkit-flex;
    display: flex;
}
/* styles the checkbox */
#editUserRoleList li div {
    margin: 0 10px 10px 0;
}
/* styles the select title box */
#editSelectUserTitleBox {
    max-width: 100%;
}
/**************************** Add User Group  **************/
#editUserGroupList {
    display: block;
}
#editUserGroupList li {
    margin-bottom: 20px;
}
/* Dialog box that extends for most of the page and replaces the table */
.bigDialogBox {
    position: relative;
    top: 60px;
    left: 330px;
    margin-right: 30px;
    height: auto;
    width: auto;
    padding: 20px 0 40px 20px;
    background: #fff;
    border: 0;
    text-align: left;
}
.bigDialogBox::-webkit-backdrop {
    background: rgba(0,0,0, .5)
}
.bigDialogBox::backdrop {
    background: rgba(0,0,0, .5)
}

/* style for the format image example format for add users in bulk*/
#bulkUserImg {
    border: solid 1px #cccccc;
    max-width: 97%;
}
/* on edit plan, edit the title of a user */
#editUserTitleBox {
    position: relative;
    top: 200px;
    height: auto;
    max-height: 55%;
    width: 20%;
    padding-top: 20px;
    padding-left: 20px;
    margin-left: 40%;
    background: #fff;
    border: 0;
    text-align: center;
}
/* on add new plan, edit the title of a user */
#editTitleBoxForPlan {
    position: relative;
    top: 200px;
    height: auto;
    max-height: 55%;
    width: 20%;
    padding-top: 20px;
    padding-left: 20px;
    margin-left: 40%;
    background: #fff;
    border: 0;
    text-align: center;
}
/************************** on new plan, displays the userList box *********/
/* style the Add New User Dialog for user Selection in ERP */
#UserBoxDialog {
    position: relative;
    top: 200px;
    /* max-height: 400px; */
    max-height: 500px;
    width: 350px;
    padding-top: 20px;
    padding-left: 20px;
    margin-left: 40%;
    background: #fff;
    border: 0;
    text-align: center;
}
/* title of the add users to ERP */
#userListBoxTitle {
    margin-top: 0px;
    font-size: 20px;
}
/****************** Abstracted the select users from planTable *************/
#userListOptions {
    display: block;
    width: 350px;
    max-height: 290px;
    overflow: scroll;
    overflow-x: hidden;
    margin-bottom: 20px;
}
/* styles the list item which is a checkbox/username */
#userListOptions li {
    display: -webkit-flex;
    display: flex;
}
/* styles the checkbox */
#userListOptions li input {
    width: 20px;
    margin-bottom: 10px;
}
/* exit from popup */
#exit-dialog-icon {
    position: absolute;
    right: 20px;
    cursor: pointer;
}
/* user list in the Roll Call Dialog */
#editRollCallUserList {
    display: block;
}
#editRollCallUserList li {
    margin-bottom: 20px;
}

.cat_radio{
  max-width: 10px;
  margin-left:20px;
}

/* controls the header element */
h1 {
	font-weight: normal;
	font-size: 40px;
	margin-left: 30px;
}
/* removes the bullet points for the unordered lists */
ul {
	padding-left: 0 !important;
	display: -webkit-flex;
	display: flex;
	list-style: none;
}
/* provides spacing and padding for the main navigation */
.topnav {
	margin: 0 0 0 30px;
	width: 24%;
}
/* styles the cursor over the list
items of the topnav of the Userpage */
.topnav li {
	cursor: pointer;
}
/* encapsulates the add buttons and the search box */

#root > main > nav {
	margin: 0 30px;
	display: -webkit-flex;
	display: flex;
	height: 80px;
	position: relative;
	background: white;
	border-radius: 10px 10px 0 0;
}
/* search box for the userlist (when selecting) */
#searchBox {
	width: 90%;
}
/* search box for the userlist (when selecting) */
#searchBoxUserList {
	width: 100%;
	margin: auto;
}
/* reset button for the userList dialog */
#resetUserListBtn {
	width: 10%;
}
/* reset button for all the table lists */
#resetTableListBtn {
	height: 40px;
	width: 40px;
	cursor: pointer;
}

/* div that contains the search box and the reset button */
#userListSearchandReset {
	display: -webkit-flex;
	display: flex;
}
/* Defines the size of the add buttons block */
.addItem {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	position: absolute;
	right: 0;
	margin-top: 25px;



}
.btn{
	color: white !important;
}
/* defines the size of the search box */
.search {
	display: -webkit-flex;
	display: flex;
	margin: 25px 0px 15px 0px;
	max-width: 350px;

}


/* moves all the column headers to the left */
table > thead > tr:nth-child(2) > th {
	text-align: left;
	color: #C9C9C9;
}

/* only removing the main table header */
table > thead > tr:nth-child(1) > th {
	display: none;
}
tr:nth-child(2) > th {
	text-align: left;
}
/* styles the ul in Userlist for (Users, Groups, Deleted) */
#secondaryNavigationItems {
	white-space: nowrap;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 10px 30px;
	text-align: center;
	background: #cfd4da;
	margin-right:1px;
	border-radius: 5px;
}
/* styling the Userlist page and Knowledge Base page navigation buttons */
.topLine {
	/* background: #21408b !important; */
	background: linear-gradient(to right, #233169, #21418c) !important;
	color: white;
	border-bottom: .5px solid black;
}
.bottomLine:hover {
	background-color:#C4DAFF !important;
}
/* .topLine:hover {
	background-color:#C4DAFF !important;
} */
/* Styling for Active Switch */
.switch {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 20px;
  }

  .switch input {
	opacity: 0;
	width: 0;
	height: 0;
  }

  .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	transition: .4s;
  }

  .slider:before {
	position: absolute;
	content: "";
	height: 18px;
	width: 18px;
	right: 21px;
	bottom: 1px;
	background-color: white;
	transition: .4s;
  }

  input:checked + .slider {
	background-color: #22397a;
  }

input:focus + .slider {
	box-shadow: 0 0 1px #22397a;
}
input:checked + .slider:before {
	-webkit-transform: translateX(20px);
	transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
/* styling the active toggle switch in the Reponse Plans Table */
#activeSwitch input:checked + .slider {
	background-color: #22397a;
}
#activeSwitch input:focus + .slider {
	box-shadow: 0 0 1px #22397a;
}
/* styling the practice toggle switch in the Reponse Plans Table */
#practiceSwitch input:checked + .slider {
	background-color: #22397a;
}
#practiceSwitch input:focus + .slider {
	box-shadow: 0 0 1px #22397a;
}
/* add Task button on the Task list page */
#taskButton, #deleteChecklist {
	color: white;
    padding: 10px 20px;
	background: linear-gradient(to right, #233169, #21418c) !important;;
    border: 0;
    border-radius: 10px;
	font-size: 13px;
	cursor: pointer;
}



/* Styles the box on the Task List page */
#taskCompletedCheckbox {
	width: 20px;
	height: 20px;
	border:3px solid #147bb5;
	border-radius: 5px;
	cursor: pointer;
}
/* Styles the text area for the task description */
#taskDescription {
	font-size: 20px;
	font-family: 'Open Sans', sans-serif;
}
.planNameDescription {
	margin-top: 0px;
	margin-left: 30px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: left;
    color: #aaaaaa;
}
/* Progress Bar in Tasklist */
#planName-ProgressBar-Div {
	display: -webkit-flex;
	display: flex;
}
/* task list progress bar desciption */
#progressBar-description {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	text-align: center;
	margin-left: 150px;
}
/* task list progress bar */
#progressBar {
	width: 400px;
	height: 30px;
	-webkit-appearance: none;
	border-radius: .25rem;
	margin-top: 75px;
}
#progressBar::-webkit-progress-value {
	background: #147bb5;
	border-radius: .25rem;
}
#progressBar::-webkit-progress-bar {
	background: #e9ecef;
	border-radius: .25rem;
}
#progressBarPercentage {
	position: relative;
	color: black;
}
/* styles the breadcrumbs throughout the website */
#breadCrumbs {
	font-size: 20px;
	margin: 30px 0 0 30px;
	cursor: pointer;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}
/* styles the icons buttons like the exit popup icon */
.icon_button {
	cursor: pointer;
	-webkit-filter: invert(17%) sepia(48%) saturate(2009%) hue-rotate(203deg) brightness(98%) contrast(92%);
	        filter: invert(17%) sepia(48%) saturate(2009%) hue-rotate(203deg) brightness(98%) contrast(92%);
}
/* Styles the box on the Task List page */
#userStatusBox {
	width: 20px;
	height: 20px;
	border:3px solid #147bb5;
	border-radius: 50%;
}

/* rc list progress bar */
#rcProgressBar1 {
	width: 400px;
	height: 30px;
	-webkit-appearance: none;
	border-radius: .25rem;
}
#rcProgressBar1::-webkit-progress-value {
	background: #078f45;
	border-radius: .25rem;
}
#rcProgressBar1::-webkit-progress-bar {
	background: #e9ecef;
	border-radius: .25rem;
}
#rcProgressBar2 {
	width: 400px;
	height: 30px;
	-webkit-appearance: none;
	border-radius: .25rem;
	margin-top: 20px;
}
#rcProgressBar2::-webkit-progress-value {
	background: #ffc60a;
	border-radius: .25rem;
}
#rcProgressBar2::-webkit-progress-bar {
	background: #e9ecef;
	border-radius: .25rem;
}
#rcProgressBar3 {
	width: 400px;
	height: 30px;
	-webkit-appearance: none;
	border-radius: .25rem;
	margin-top: 20px;
}
#rcProgressBar3::-webkit-progress-value {
	background: #cc3232;
	border-radius: .25rem;
}
#rcProgressBar3::-webkit-progress-bar {
	background: #e9ecef;
	border-radius: .25rem;
}
/* Roll Call User List page style filter select */
#selectFilter {
	margin: auto 0;
	height: 23px;
	width: 110px;
	-webkit-appearance: none;
	text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;
}
/* styles the react select input box makes the box fit */
#searchBox input {
	margin: auto !important;
}
/* the chat box that opens up */
#chatBox {
	border-radius: 10px;
	border: 3px solid #147bb5;
	height: 500px;
	width: 350px;
	position: fixed;
    bottom: 100px;
    margin-right: 100px;
}
/* user input for the new message */
#chatBoxInput {
	border-radius: 20px;
	border-width: 1px;
	position: absolute;
	bottom: 5px;
	left: 5px;
	padding: 3px 10px;
	background: rgba(0, 0, 0, .05);
	width: -webkit-fill-available;
	margin-right: 25px;
	margin-bottom: 0px;
	text-indent: 17px;
}
/* click on element to add image (plus sign) */
#chatBoxSelectImage {
	opacity: 0;
	width: 0;
    height: 0;
}
/* plus sign that is shown in chat box */
#chatBoxPlusSign {
	font-size: 35px;
    position: absolute;
	bottom: 24px;
	width: 20px;
	height: 20px;
	left: 8px;
	cursor: pointer;
	color: #147bb5;
	z-index: 10;
}
/* the box of all the previous messages */
#messagesBox {
	position: absolute;
	left: 8px;
	margin-right: 28px;
    width: -webkit-fill-available;
	border: 1px solid black;
	border-radius: 5px;
	height: 427px;
	overflow: scroll;
}
/* list of messages */
#messageList {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin-top: 0px;
}
#messageList li {
	margin-bottom: 5px;
	padding: 3px 10px;
	border-radius: 20px;
	max-width: 250px;
	width: -webkit-fit-content;
	width: fit-content;
  	width: -moz-fit-content;
}
/* send chat button */
#sendChatBtn {
	visibility: hidden;
}
/* current person in the chat */
.currUserMessage {
	background-color: lightblue;
	margin-left: auto;
}
/* The other person in the chat */
.otherUserMessage {
	background: #E5E5EA;
}
/* name of the other user who sent the message */
.otherUserName {
	font-size: 15px;
	color: lightgray;
}
/* name of the user who sent the message */
.currUserName {
	font-size: 15px;
	color: lightgray;
	margin-left: auto;
}
/* the box of all the previous messages */
#convoBox {
	position: absolute;
	bottom: 16px;
	border: 1px solid black;
	border-radius: 5px;
	height: 450px;
	width: 349px;
	overflow: scroll;
}
/* list of messages */
#convoList {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin-top: 0px;
}
/* each individual message */
#convoList li {
	text-align: center;
	padding: 10px 0;
	border: 1px solid black;
	cursor: pointer;
}
/* Title of a chat between 2 people */
#chatTitle {
	text-align: center;
	margin-bottom: 8px;
}

#filterArrow {
	position: relative;
	top: 3px;
	height: 17px;
	cursor: pointer;
}
/* styles the container that holds the table and the piechart */
#dashboardPage article {
	display: -webkit-flex;
	display: flex;
}
#dashboardTableCont > div > table {
	background: white;
    border-radius: 10px;
}
/* styles the dashboard table */
#dashboardPage table {
	margin: 0 30px;
    width: 100%;
    height: 50%;
    overflow: scroll;
}
/* styles the div that holds the table and the div that holds the pie chart */
#dashboardPage article div {
	width: 50%;
}
/* styles the pie chart and the stats */
#pieChartBox {
	position: relative;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    left: 100px;
}
#pieChartStat {
	margin-bottom: 10px;
	background: #22397a;
    font-size: 20px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	padding: 5px 30px;
	border-radius: 5px;
	color: white;
	text-align: left;
	position: relative;
	left: 100px;
}
#can {
    border: 2px solid #C9C9C9;
	border-radius: 50%;
	height: 300px;
	width: 300px;
}
#admincan {
    border: 2px solid #C9C9C9;
	border-radius: 50%;
	height: 300px;
	width: 300px;
}
#pieTitle {
	font-size: 20px;
	color: #C9C9C9;
	font-weight: bold;
	margin-top: 0 !important;
}

/* styles the pie chart and the stats */
#pieChartBoxSuperAdmin {
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin-left: 30px;
}

/* unread messages */
.dot {
	height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

/* Root admin select client to filter userlist  */
#selectClientUserTable {
    font-size: 15px;
    height: 40px;
    color:gray;
    position: absolute;
    margin-top: 25px;
    padding-left: 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    max-width: 260px;


}

.tooltip{
	width:100px;
	height:100px;
}

/* styles the arrow to the left of the path header */
i {
    border: solid #aaaaaa;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 4px;
}
.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
}
/* styles the text in the path header */
.pathHeader {
    margin-left: 30px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    cursor: pointer;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: left;
    color: #aaaaaa;
}
/* styles the Delete Folder button */
.folderBtn {
    width: 140px;
    margin: 0;
    margin-left: 3px;
    padding: 1em 2em;
    color: #fff;
    border: 0;
    border-radius: 10px;
    font-size: 13px;
}
.deleteFolderBtn {
    background: #ff0000;
}
.editFolderBtn {
    background: #036aa4;
}



/* styling the reports page */

/* PRINT BUTTON */
.icon-button-print{
    margin-top: 25px;
    margin-right: 1.2em;
}
.printButton{
    margin-left: 10%;
}


/* TOTAL RESULT */
.totalresultdiv{
    margin-top: 10px;
    width: 100px;
    margin-right: 1em;
}
.totalresultspan{
    font-size: 15px;
}
.totalresultsp{
    background: lightgrey;
    text-align: center;
    padding: 5%;

}


/* DATE RANGE */
.dateRange{
    display: -webkit-flex;
    display: flex;
    max-width: 100%;
    margin-top: 25px;

}
.startDate{
    margin-right: 5%;
}


.datepickerStyle {
    outline: 0;
    border-radius: 2px;
    border: 1px solid lightgrey;
    font-size: 18px;
    max-width: 150px;
    height: 40px;
    padding: 20px;
}

/* LABEL */
.label{
    color: grey;
}

table > thead > tr:nth-child(2) > th{
    color: #036aa4;
}


.planNameDescription {
    color: #036aa4;
    }

.go{
        margin: 0px 10px 48px 0px;
        border: 1px solid lightgrey;
        border-radius: 2px;  
        padding: 7px 22px 7px 22px; 
    }

/* GO button */
/* @media screen and (min-width: 1550px){
  
}
@media screen and (min-width: 1268px) and (max-width: 1549px){
  .go{
        margin: 0px 0px 25px 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 13px;
        border: 1px solid lightgrey;
        border-radius: 2px;   
    }
    .icon-button-print {
    margin: 25px 0 0 0px;
   }
   #selectClientUserTable {
    font-size: 17px;
    width: 20%;
    margin-top: 20px;
    color: #808080;
    top: 0;
    bottom: 0;
    margin-left: 54%;
    position: absolute;
    left: 0;
    right: 0;
    height: 38px;
    padding-left: 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;
}
}
@media screen and (min-width: 1130px) and (max-width: 1267px){
  .go{
        margin: 0px 0px 17px 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 13px;
        border: 1px solid lightgrey;
        border-radius: 2px;    
    }
}
@media screen and (min-width: 873px) and (max-width: 1129px){
  .go{
        margin: 0px 0px 24px 15px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 13px;
        border: 1px solid lightgrey;
        border-radius: 2px;    
    }
}
@media screen and (min-width: 701px) and (max-width: 872px){
  .go{
        margin: 0px 0px 30px 10px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 13px;
        border: 1px solid lightgrey;
        border-radius: 2px;    
    }
}
@media screen and (max-width: 700px){
  .go{
        margin: 0px 0px 36px 15px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 13px;
        border: 1px solid lightgrey;
        border-radius: 2px;    
    }
} */
#reminderButton{
    color: white;
    padding: 10px 20px;
    background: linear-gradient(to right, #233169, #21418c) !important;
    border: 0;
    border-radius: 10px;
    font-size: 13px;
    cursor: pointer;
}

.bigDialogBox{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}







.eventDetailBlock{
	border-style: solid;
    border-width: thin;
    border-color: lightgray;
}
.detailBlockTitle{
	color: lightgray;
}

.search{
	max-width:600px !important
}

#activateEventButton{
	color: white;
	padding: 10px 20px;
	background: linear-gradient(to right, #233169, #21418c) !important;;
	border: 0;
	border-radius: 10px;
	font-size: 13px;
	cursor: pointer;
}

/* controls the header element */
h1 {
	font-weight: normal;
	font-size: 40px;
	margin-left: 30px;
}
/* removes the bullet points for the unordered lists */
ul {
	padding-left: 0 !important;
	display: -webkit-flex;
	display: flex;
	list-style: none;
}
/* provides spacing and padding for the main navigation */
.topnav {
	margin: 0 0 0 30px;
	width: 24%;
}
/* styles the cursor over the list
items of the topnav of the Userpage */
.topnav li {
	cursor: pointer;
}
/* encapsulates the add buttons and the search box */

#root > main > nav {
	margin: 0 30px;
	display: -webkit-flex;
	display: flex;
	height: 80px;
	position: relative;
	background: white;
	border-radius: 10px 10px 0 0;
}
/* search box for the userlist (when selecting) */
#searchBox {
	width: 90%;
}
/* search box for the userlist (when selecting) */
#searchBoxUserList {
	width: 100%;
	margin: auto;
}
/* reset button for the userList dialog */
#resetUserListBtn {
	width: 10%;
}
/* reset button for all the table lists */
#resetTableListBtn {
	height: 40px;
	width: 40px;
	cursor: pointer;
}

/* div that contains the search box and the reset button */
#userListSearchandReset {
	display: -webkit-flex;
	display: flex;
}
/* Defines the size of the add buttons block */
.addItem {
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	position: absolute;
	right: 0;
	margin-top: 25px;



}
.btn{
	color: white !important;
}
/* defines the size of the search box */
.search {
	display: -webkit-flex;
	display: flex;
	margin: 25px 0px 15px 0px;
	max-width: 350px;

}


/* moves all the column headers to the left */
table > thead > tr:nth-child(2) > th {
	text-align: left;
	color: #C9C9C9;
}

/* only removing the main table header */
table > thead > tr:nth-child(1) > th {
	display: none;
}
tr:nth-child(2) > th {
	text-align: left;
}
/* styles the ul in Userlist for (Users, Groups, Deleted) */
#secondaryNavigationItems {
	white-space: nowrap;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 10px 30px;
	text-align: center;
	background: #cfd4da;
	margin-right:1px;
	border-radius: 5px;
}
/* styling the Userlist page and Knowledge Base page navigation buttons */
.topLine {
	/* background: #21408b !important; */
	background: linear-gradient(to right, #233169, #21418c) !important;
	color: white;
	border-bottom: .5px solid black;
}
.bottomLine:hover {
	background-color:#C4DAFF !important;
}
/* .topLine:hover {
	background-color:#C4DAFF !important;
} */
/* Styling for Active Switch */
.switch {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 20px;
  }

  .switch input {
	opacity: 0;
	width: 0;
	height: 0;
  }

  .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	transition: .4s;
  }

  .slider:before {
	position: absolute;
	content: "";
	height: 18px;
	width: 18px;
	right: 21px;
	bottom: 1px;
	background-color: white;
	transition: .4s;
  }

  input:checked + .slider {
	background-color: #22397a;
  }

input:focus + .slider {
	box-shadow: 0 0 1px #22397a;
}
input:checked + .slider:before {
	-webkit-transform: translateX(20px);
	transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
/* styling the active toggle switch in the Reponse Plans Table */
#activeSwitch input:checked + .slider {
	background-color: #22397a;
}
#activeSwitch input:focus + .slider {
	box-shadow: 0 0 1px #22397a;
}
/* styling the practice toggle switch in the Reponse Plans Table */
#practiceSwitch input:checked + .slider {
	background-color: #22397a;
}
#practiceSwitch input:focus + .slider {
	box-shadow: 0 0 1px #22397a;
}
/* add Task button on the Task list page */
#taskButton, #deleteChecklist {
	color: white;
    padding: 10px 20px;
	background: linear-gradient(to right, #233169, #21418c) !important;;
    border: 0;
    border-radius: 10px;
	font-size: 13px;
	cursor: pointer;
}



/* Styles the box on the Task List page */
#taskCompletedCheckbox {
	width: 20px;
	height: 20px;
	border:3px solid #147bb5;
	border-radius: 5px;
	cursor: pointer;
}
/* Styles the text area for the task description */
#taskDescription {
	font-size: 20px;
	font-family: 'Open Sans', sans-serif;
}
.planNameDescription {
	margin-top: 0px;
	margin-left: 30px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: left;
    color: #aaaaaa;
}
/* Progress Bar in Tasklist */
#planName-ProgressBar-Div {
	display: -webkit-flex;
	display: flex;
}
/* task list progress bar desciption */
#progressBar-description {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	text-align: center;
	margin-left: 150px;
}
/* task list progress bar */
#progressBar {
	width: 400px;
	height: 30px;
	-webkit-appearance: none;
	border-radius: .25rem;
	margin-top: 75px;
}
#progressBar::-webkit-progress-value {
	background: #147bb5;
	border-radius: .25rem;
}
#progressBar::-webkit-progress-bar {
	background: #e9ecef;
	border-radius: .25rem;
}
#progressBarPercentage {
	position: relative;
	color: black;
}
/* styles the breadcrumbs throughout the website */
#breadCrumbs {
	font-size: 20px;
	margin: 30px 0 0 30px;
	cursor: pointer;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
}
/* styles the icons buttons like the exit popup icon */
.icon_button {
	cursor: pointer;
	-webkit-filter: invert(17%) sepia(48%) saturate(2009%) hue-rotate(203deg) brightness(98%) contrast(92%);
	        filter: invert(17%) sepia(48%) saturate(2009%) hue-rotate(203deg) brightness(98%) contrast(92%);
}
/* Styles the box on the Task List page */
#userStatusBox {
	width: 20px;
	height: 20px;
	border:3px solid #147bb5;
	border-radius: 50%;
}

/* rc list progress bar */
#rcProgressBar1 {
	width: 400px;
	height: 30px;
	-webkit-appearance: none;
	border-radius: .25rem;
}
#rcProgressBar1::-webkit-progress-value {
	background: #078f45;
	border-radius: .25rem;
}
#rcProgressBar1::-webkit-progress-bar {
	background: #e9ecef;
	border-radius: .25rem;
}
#rcProgressBar2 {
	width: 400px;
	height: 30px;
	-webkit-appearance: none;
	border-radius: .25rem;
	margin-top: 20px;
}
#rcProgressBar2::-webkit-progress-value {
	background: #ffc60a;
	border-radius: .25rem;
}
#rcProgressBar2::-webkit-progress-bar {
	background: #e9ecef;
	border-radius: .25rem;
}
#rcProgressBar3 {
	width: 400px;
	height: 30px;
	-webkit-appearance: none;
	border-radius: .25rem;
	margin-top: 20px;
}
#rcProgressBar3::-webkit-progress-value {
	background: #cc3232;
	border-radius: .25rem;
}
#rcProgressBar3::-webkit-progress-bar {
	background: #e9ecef;
	border-radius: .25rem;
}
/* Roll Call User List page style filter select */
#selectFilter {
	margin: auto 0;
	height: 23px;
	width: 110px;
	-webkit-appearance: none;
	text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;
}
/* styles the react select input box makes the box fit */
#searchBox input {
	margin: auto !important;
}
/* the chat box that opens up */
#chatBox {
	border-radius: 10px;
	border: 3px solid #147bb5;
	height: 500px;
	width: 350px;
	position: fixed;
    bottom: 100px;
    margin-right: 100px;
}
/* user input for the new message */
#chatBoxInput {
	border-radius: 20px;
	border-width: 1px;
	position: absolute;
	bottom: 5px;
	left: 5px;
	padding: 3px 10px;
	background: rgba(0, 0, 0, .05);
	width: -webkit-fill-available;
	margin-right: 25px;
	margin-bottom: 0px;
	text-indent: 17px;
}
/* click on element to add image (plus sign) */
#chatBoxSelectImage {
	opacity: 0;
	width: 0;
    height: 0;
}
/* plus sign that is shown in chat box */
#chatBoxPlusSign {
	font-size: 35px;
    position: absolute;
	bottom: 24px;
	width: 20px;
	height: 20px;
	left: 8px;
	cursor: pointer;
	color: #147bb5;
	z-index: 10;
}
/* the box of all the previous messages */
#messagesBox {
	position: absolute;
	left: 8px;
	margin-right: 28px;
    width: -webkit-fill-available;
	border: 1px solid black;
	border-radius: 5px;
	height: 427px;
	overflow: scroll;
}
/* list of messages */
#messageList {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin-top: 0px;
}
#messageList li {
	margin-bottom: 5px;
	padding: 3px 10px;
	border-radius: 20px;
	max-width: 250px;
	width: -webkit-fit-content;
	width: fit-content;
  	width: -moz-fit-content;
}
/* send chat button */
#sendChatBtn {
	visibility: hidden;
}
/* current person in the chat */
.currUserMessage {
	background-color: lightblue;
	margin-left: auto;
}
/* The other person in the chat */
.otherUserMessage {
	background: #E5E5EA;
}
/* name of the other user who sent the message */
.otherUserName {
	font-size: 15px;
	color: lightgray;
}
/* name of the user who sent the message */
.currUserName {
	font-size: 15px;
	color: lightgray;
	margin-left: auto;
}
/* the box of all the previous messages */
#convoBox {
	position: absolute;
	bottom: 16px;
	border: 1px solid black;
	border-radius: 5px;
	height: 450px;
	width: 349px;
	overflow: scroll;
}
/* list of messages */
#convoList {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin-top: 0px;
}
/* each individual message */
#convoList li {
	text-align: center;
	padding: 10px 0;
	border: 1px solid black;
	cursor: pointer;
}
/* Title of a chat between 2 people */
#chatTitle {
	text-align: center;
	margin-bottom: 8px;
}

#filterArrow {
	position: relative;
	top: 3px;
	height: 17px;
	cursor: pointer;
}
/* styles the container that holds the table and the piechart */
#dashboardPage article {
	display: -webkit-flex;
	display: flex;
}
#dashboardTableCont > div > table {
	background: white;
    border-radius: 10px;
}
/* styles the dashboard table */
#dashboardPage table {
	margin: 0 30px;
    width: 100%;
    height: 50%;
    overflow: scroll;
}
/* styles the div that holds the table and the div that holds the pie chart */
#dashboardPage article div {
	width: 50%;
}
/* styles the pie chart and the stats */
#pieChartBox {
	position: relative;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    left: 100px;
}
#pieChartStat {
	margin-bottom: 10px;
	background: #22397a;
    font-size: 20px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	height: -webkit-fit-content;
	height: -moz-fit-content;
	height: fit-content;
	padding: 5px 30px;
	border-radius: 5px;
	color: white;
	text-align: left;
	position: relative;
	left: 100px;
}
#can {
    border: 2px solid #C9C9C9;
	border-radius: 50%;
	height: 300px;
	width: 300px;
}
#admincan {
    border: 2px solid #C9C9C9;
	border-radius: 50%;
	height: 300px;
	width: 300px;
}
#pieTitle {
	font-size: 20px;
	color: #C9C9C9;
	font-weight: bold;
	margin-top: 0 !important;
}

/* styles the pie chart and the stats */
#pieChartBoxSuperAdmin {
	-webkit-flex-direction: column;
	        flex-direction: column;
	margin-left: 30px;
}

/* unread messages */
.dot {
	height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

/* Root admin select client to filter userlist  */
#selectClientUserTable {
    font-size: 15px;
    height: 40px;
    color:gray;
    position: absolute;
    margin-top: 25px;
    padding-left: 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    max-width: 260px;


}

.tooltip{
	width:100px;
	height:100px;
}

#dashboardPage article div {
    width: 95%;

}
#dashboardPage table {
    margin: 0 30px;
    width: 98%;
    height: 50%;
    overflow: scroll;
}

.formsType{
	padding: 2%;
    border: 1px solid rgb(34 62 133 / 20%);
    width: 95%;
    margin-left: 30px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    transition: 0.3s;
    border-radius: 5px;
    margin-bottom: 1%;
}
.formTable{
	border-top: 1px solid lightgrey;
    box-shadow: inset 1px -1px 8px 0 rgb(0 0 0 / 20%);
    transition: 0.3s;
    border-radius: 5px;
    margin-left: 30px;
}

.pro-sidebar{
	width: 170px;
	min-width: 170px;
	margin-top: 5%;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
    position: absolute;
    padding-top: 80%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper{
	background: #ffffff !important;
}

.pro-sidebar > .pro-sidebar-inner {
    background: #ffffff !important;
    font-family: Open Sans,sans-serif;
}
.pro-sidebar .pro-menu {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item{
	padding:  0px !important;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item{
	background-color: #ffffff !important;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
    position: absolute;
    left: 10px;
    right: 0px;
    -webkit-transform: translateY(80%);
            transform: translateY(80%);
    margin-top: 25px;
 }
 .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
    max-height: 100vh;
    overflow-y: auto;
    background-color: #22397a !important;
    padding-left: 20px;
    border-radius: 4px;
    color: #ffffff !important;
}
.pro-sidebar .pro-menu .pro-menu-item {
    font-size: 20px !important;
}

/* $submenu-bg-color-collapsed: #ffffff !default; */
/* $sidebar-bg-color: #1d1d1d !default;
$sidebar-color: #adadad !default;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #d8d8d8 !default;
$submenu-bg-color: #2b2b2b !default;
$submenu-bg-color-collapsed: #2b2b2b !default;
$icon-bg-color: #2b2b2b !default;
$icon-size: 35px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default; */




/* styling the login page */
.signIn {
    max-width: 500px;
    max-height: 500px;
    padding: 20px;
    margin-top: 5%;
    text-align: center;
    border-style: solid;
    border-width: 25px;
    border-radius: 5px;
    border-color: rgb(218,185,61);
    background: white;
}
.logo {
    margin: 20px 0;
}

.lgnTitle {
    font-size: 18px;
    margin: auto;
    margin-bottom: 20px;
    font-weight: bold;
}
/* the div around the input */
.inputbox {
    text-align: center;
}
/* the actual input tag */
.logInput {
    padding: 5px;
    width: 300px;
    border: 1px solid rgb(224, 228, 232);
    margin-bottom: 5px;
}
.ltag {
    font-size: 20px;
}
.signInBtn {
    width: 140px;
    margin: 0;
    padding: 1em 2em;
    color: #fff;
    background: rgb(218,185,61);
    border: 0;
    border-radius: 10px;
    font-size: 15px;
}

