/* Button */
.btn {
    width: 140px;
    margin: 0 10px 0 0;
    padding: 1em 2em;
    color: #fff;
    background: linear-gradient(to right, #233169, #21418c) !important;
    border: 0;
    border-radius: 10px;
    font-size: 13px;
    cursor: pointer;
}
/* controls the buttons inside the Modal */
.btn--flex {
    display: flex;
    height: 35px;
    width: 290px;
    font-size: 20px;
}
/* styles the cancel button */
.btn--close {
    border-radius: 10px;
    background: rgb(53, 116, 174);
    cursor: pointer;
}
/* styles the confirm button*/
.btn--confirm {
    border-radius: 10px;
    background: rgb(53, 116, 174);
    cursor: pointer;
}

.dialog__btn {
    display: block;
    width: 100%;
    padding-bottom: 2em;
    padding-top: .7em;
}
h2 {
    font-weight: normal;
    font-size: 40px;
    text-align: left;
    margin-bottom: 20px;
}
label {
    font-size: 16px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: left;
    color: #1d1d1d;
}
/* inputs for Add User dialog */
input {
    outline: 0;
    border-width: 0 0 1px;
    border-color: black;
    font-size: 20px;
    width: 545px;
    margin-bottom: 25px;
}
/* changes the color of the placeholder for the inputs */
::placeholder {
    color: #C9C9C9;
}
/* controls the input for the admin level options */
select {
    font-size: 15px;
    margin-bottom: 10px;
    width: 545px;
}
/* controls the input for the profile picture */
.inputProfile {
    text-align: center;
    position: relative;
    display: inline-block;
    width: 100px;
    height: 100px;
    border: solid 0.5px #cccccc;
    margin-top: 5px;
    margin-bottom: 17px;
}
.inputProfile input {
    opacity: 0;
    width: 0;
    height: 0;
}
.inputProfile img {
    margin-top: 12.5px;
    height: 75px;
    width: 75px;
}
/* In addfileDialog.js -> create new Subfolder */
/* styles the button that creates a new subfolder */
#CreateNewSub {
    padding: 1em 2em;
    border-radius: 10px;
    background: rgb(53, 116, 174);
    color: white;
    margin-bottom: 10px;
    font-size: 13px;
}
/* styles the select below the create new subfolder */
#selectSubfolder {
    margin-bottom: 20px;
}

/* styles the add button on the addPlanDialog.js */
.addItemPlan {
    text-align: center;
    font-size: 25px;
    font-weight: bolder;
    border-radius: 3px;
    border: solid 3px #147bb5;
    color: #147bb5;
    margin: 0;
    padding: 0 10px 2px 10px;
    cursor: pointer;
}
.addItemText {
    position: relative;
    top: -3px;
    margin-left: 12px;
}
/********** styles the selected users in create Plan page */
#selectedUsersList {
    display: block;
    width: 350px;
    margin: 20px 0;
}
/* styles the pencil icon to the right of the user name */
.selectedUserPencilIcon {
    position: absolute;
    padding-top: 8px;
    left: 350px;
    cursor: pointer;
    filter: invert(17%) sepia(48%) saturate(2009%) hue-rotate(203deg) brightness(98%) contrast(92%) !important;
}
/* styles the minus sign to the right of the title/role */
.selectedUserMinusSign {
    position: absolute;
    left: 405px;
    font-size: 32px;
    color: red;
    font-weight: bolder;
    border-radius: 3px;
    border: solid 3px red;
    line-height: 13px;
    padding: 0 5px;
    height: 20px;
    cursor: pointer;
}
/* for the task list, moved the edit icon sign to the right so
 * it does not overlap with the minus sign */
.taskPencil {
    left: 377px;
}
.taskMinusSign {
    margin-top: 5px;
}
/* adding some margin between all the users */
#selectedUsersList li {
    margin-bottom: 20px;
}
/*************** TITLE list that is displayed in the ERP page */
#titleList {
    display: block;
    width: 350px;
    margin: 20px 0;
}
/* added some margin below the task item */
#titleList > li {
    margin-bottom: 20px;
}

/*************** new task list that is displayed in the ERP page */
#newTasksList {
    display: block;
    width: 350px;
    margin: 20px 0;
}
/* added some margin below the task item */
#newTasksList > li {
    margin-bottom: 20px;
}
/* On edit user title in ERP, styles the UL that stores the roles */
#userRoleList {
    display: block;
    width: 350px;
    height: 150px;
}
/* styles the list item which is a checkbox/username */
#userRoleList li {
    display: flex;
}
/* styles the checkbox */
#userRoleList li div {
    margin: 0 10px 10px 0;
}
/* edit the users title for the ERP */
#editTitle {
    width: 325px;
}
/* controls the size of the dropdown for user role */
#selectUserTitle {
    width: 325px;
}
/*************** Edit Plan Box UserList *************/
#editSelectedUsersList {
    display: block;
    width: 350px;
    margin: 20px 0;
}

/* adding some margin between all the users */
#editSelectedUsersList li {
    margin-bottom: 20px;
}
/* new task list that is displayed in the ERP page */
#editTasksList {
    display: block;
    width: 350px;
    margin: 20px 0;
}
/* added some margin below the task item */
#editTasksList > li {
    margin-bottom: 20px;
}
/* title list that is displayed in the ERP page */
#editTitleList {
    display: block;
    width: 350px;
    margin: 20px 0;
}
/* added some margin below the title item */
#editTitleList > li {
    margin-bottom: 20px;
}
/********************** EDIT ERP Page ********/
/* role list this is displayed in the edit ERP page */
#editUserRoleList {
    display: block;
    width: 350px;
    height: 150px;
}
/* styles the list item which is a checkbox/username */
#editUserRoleList li {
    display: flex;
}
/* styles the checkbox */
#editUserRoleList li div {
    margin: 0 10px 10px 0;
}
/* styles the select title box */
#editSelectUserTitleBox {
    max-width: 100%;
}
/**************************** Add User Group  **************/
#editUserGroupList {
    display: block;
}
#editUserGroupList li {
    margin-bottom: 20px;
}
/* Dialog box that extends for most of the page and replaces the table */
.bigDialogBox {
    position: relative;
    top: 60px;
    left: 330px;
    margin-right: 30px;
    height: auto;
    width: auto;
    padding: 20px 0 40px 20px;
    background: #fff;
    border: 0;
    text-align: left;
}
.bigDialogBox::backdrop {
    background: rgba(0,0,0, .5)
}

/* style for the format image example format for add users in bulk*/
#bulkUserImg {
    border: solid 1px #cccccc;
    max-width: 97%;
}
/* on edit plan, edit the title of a user */
#editUserTitleBox {
    position: relative;
    top: 200px;
    height: auto;
    max-height: 55%;
    width: 20%;
    padding-top: 20px;
    padding-left: 20px;
    margin-left: 40%;
    background: #fff;
    border: 0;
    text-align: center;
}
/* on add new plan, edit the title of a user */
#editTitleBoxForPlan {
    position: relative;
    top: 200px;
    height: auto;
    max-height: 55%;
    width: 20%;
    padding-top: 20px;
    padding-left: 20px;
    margin-left: 40%;
    background: #fff;
    border: 0;
    text-align: center;
}
/************************** on new plan, displays the userList box *********/
/* style the Add New User Dialog for user Selection in ERP */
#UserBoxDialog {
    position: relative;
    top: 200px;
    /* max-height: 400px; */
    max-height: 500px;
    width: 350px;
    padding-top: 20px;
    padding-left: 20px;
    margin-left: 40%;
    background: #fff;
    border: 0;
    text-align: center;
}
/* title of the add users to ERP */
#userListBoxTitle {
    margin-top: 0px;
    font-size: 20px;
}
/****************** Abstracted the select users from planTable *************/
#userListOptions {
    display: block;
    width: 350px;
    max-height: 290px;
    overflow: scroll;
    overflow-x: hidden;
    margin-bottom: 20px;
}
/* styles the list item which is a checkbox/username */
#userListOptions li {
    display: flex;
}
/* styles the checkbox */
#userListOptions li input {
    width: 20px;
    margin-bottom: 10px;
}
/* exit from popup */
#exit-dialog-icon {
    position: absolute;
    right: 20px;
    cursor: pointer;
}
/* user list in the Roll Call Dialog */
#editRollCallUserList {
    display: block;
}
#editRollCallUserList li {
    margin-bottom: 20px;
}

.cat_radio{
  max-width: 10px;
  margin-left:20px;
}
