


/* styling the reports page */

/* PRINT BUTTON */
.icon-button-print{
    margin-top: 25px;
    margin-right: 1.2em;
}
.printButton{
    margin-left: 10%;
}


/* TOTAL RESULT */
.totalresultdiv{
    margin-top: 10px;
    width: 100px;
    margin-right: 1em;
}
.totalresultspan{
    font-size: 15px;
}
.totalresultsp{
    background: lightgrey;
    text-align: center;
    padding: 5%;

}


/* DATE RANGE */
.dateRange{
    display: flex;
    max-width: 100%;
    margin-top: 25px;

}
.startDate{
    margin-right: 5%;
}


.datepickerStyle {
    outline: 0;
    border-radius: 2px;
    border: 1px solid lightgrey;
    font-size: 18px;
    max-width: 150px;
    height: 40px;
    padding: 20px;
}

/* LABEL */
.label{
    color: grey;
}

table > thead > tr:nth-child(2) > th{
    color: #036aa4;
}


.planNameDescription {
    color: #036aa4;
    }

.go{
        margin: 0px 10px 48px 0px;
        border: 1px solid lightgrey;
        border-radius: 2px;  
        padding: 7px 22px 7px 22px; 
    }

/* GO button */
/* @media screen and (min-width: 1550px){
  
}
@media screen and (min-width: 1268px) and (max-width: 1549px){
  .go{
        margin: 0px 0px 25px 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 13px;
        border: 1px solid lightgrey;
        border-radius: 2px;   
    }
    .icon-button-print {
    margin: 25px 0 0 0px;
   }
   #selectClientUserTable {
    font-size: 17px;
    width: 20%;
    margin-top: 20px;
    color: #808080;
    top: 0;
    bottom: 0;
    margin-left: 54%;
    position: absolute;
    left: 0;
    right: 0;
    height: 38px;
    padding-left: 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;
}
}
@media screen and (min-width: 1130px) and (max-width: 1267px){
  .go{
        margin: 0px 0px 17px 0px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 13px;
        border: 1px solid lightgrey;
        border-radius: 2px;    
    }
}
@media screen and (min-width: 873px) and (max-width: 1129px){
  .go{
        margin: 0px 0px 24px 15px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 13px;
        border: 1px solid lightgrey;
        border-radius: 2px;    
    }
}
@media screen and (min-width: 701px) and (max-width: 872px){
  .go{
        margin: 0px 0px 30px 10px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 13px;
        border: 1px solid lightgrey;
        border-radius: 2px;    
    }
}
@media screen and (max-width: 700px){
  .go{
        margin: 0px 0px 36px 15px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 13px;
        border: 1px solid lightgrey;
        border-radius: 2px;    
    }
} */
#reminderButton{
    color: white;
    padding: 10px 20px;
    background: linear-gradient(to right, #233169, #21418c) !important;
    border: 0;
    border-radius: 10px;
    font-size: 13px;
    cursor: pointer;
}

.bigDialogBox{
    height: fit-content;
}






