.eventDetailBlock{
	border-style: solid;
    border-width: thin;
    border-color: lightgray;
}
.detailBlockTitle{
	color: lightgray;
}

.search{
	max-width:600px !important
}

#activateEventButton{
	color: white;
	padding: 10px 20px;
	background: linear-gradient(to right, #233169, #21418c) !important;;
	border: 0;
	border-radius: 10px;
	font-size: 13px;
	cursor: pointer;
}
