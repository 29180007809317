/* controls the header element */
h1 {
	font-weight: normal;
	font-size: 40px;
	margin-left: 30px;
}
/* removes the bullet points for the unordered lists */
ul {
	padding-left: 0 !important;
	display: flex;
	list-style: none;
}
/* provides spacing and padding for the main navigation */
.topnav {
	margin: 0 0 0 30px;
	width: 24%;
}
/* styles the cursor over the list
items of the topnav of the Userpage */
.topnav li {
	cursor: pointer;
}
/* encapsulates the add buttons and the search box */

#root > main > nav {
	margin: 0 30px;
	display: flex;
	height: 80px;
	position: relative;
	background: white;
	border-radius: 10px 10px 0 0;
}
/* search box for the userlist (when selecting) */
#searchBox {
	width: 90%;
}
/* search box for the userlist (when selecting) */
#searchBoxUserList {
	width: 100%;
	margin: auto;
}
/* reset button for the userList dialog */
#resetUserListBtn {
	width: 10%;
}
/* reset button for all the table lists */
#resetTableListBtn {
	height: 40px;
	width: 40px;
	cursor: pointer;
}

/* div that contains the search box and the reset button */
#userListSearchandReset {
	display: flex;
}
/* Defines the size of the add buttons block */
.addItem {
	width: fit-content;
	position: absolute;
	right: 0;
	margin-top: 25px;



}
.btn{
	color: white !important;
}
/* defines the size of the search box */
.search {
	display: flex;
	margin: 25px 0px 15px 0px;
	max-width: 350px;

}


/* moves all the column headers to the left */
table > thead > tr:nth-child(2) > th {
	text-align: left;
	color: #C9C9C9;
}

/* only removing the main table header */
table > thead > tr:nth-child(1) > th {
	display: none;
}
tr:nth-child(2) > th {
	text-align: left;
}
/* styles the ul in Userlist for (Users, Groups, Deleted) */
#secondaryNavigationItems {
	white-space: nowrap;
	width: fit-content;
	padding: 10px 30px;
	text-align: center;
	background: #cfd4da;
	margin-right:1px;
	border-radius: 5px;
}
/* styling the Userlist page and Knowledge Base page navigation buttons */
.topLine {
	/* background: #21408b !important; */
	background: linear-gradient(to right, #233169, #21418c) !important;
	color: white;
	border-bottom: .5px solid black;
}
.bottomLine:hover {
	background-color:#C4DAFF !important;
}
/* .topLine:hover {
	background-color:#C4DAFF !important;
} */
/* Styling for Active Switch */
.switch {
	position: relative;
	display: inline-block;
	width: 40px;
	height: 20px;
  }

  .switch input {
	opacity: 0;
	width: 0;
	height: 0;
  }

  .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: .4s;
	transition: .4s;
  }

  .slider:before {
	position: absolute;
	content: "";
	height: 18px;
	width: 18px;
	right: 21px;
	bottom: 1px;
	background-color: white;
	-webkit-transition: .4s;
	transition: .4s;
  }

  input:checked + .slider {
	background-color: #22397a;
  }

input:focus + .slider {
	box-shadow: 0 0 1px #22397a;
}
input:checked + .slider:before {
	-webkit-transform: translateX(20px);
	-ms-transform: translateX(20px);
	transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
	border-radius: 34px;
}

.slider.round:before {
	border-radius: 50%;
}
/* styling the active toggle switch in the Reponse Plans Table */
#activeSwitch input:checked + .slider {
	background-color: #22397a;
}
#activeSwitch input:focus + .slider {
	box-shadow: 0 0 1px #22397a;
}
/* styling the practice toggle switch in the Reponse Plans Table */
#practiceSwitch input:checked + .slider {
	background-color: #22397a;
}
#practiceSwitch input:focus + .slider {
	box-shadow: 0 0 1px #22397a;
}
/* add Task button on the Task list page */
#taskButton, #deleteChecklist {
	color: white;
    padding: 10px 20px;
	background: linear-gradient(to right, #233169, #21418c) !important;;
    border: 0;
    border-radius: 10px;
	font-size: 13px;
	cursor: pointer;
}



/* Styles the box on the Task List page */
#taskCompletedCheckbox {
	width: 20px;
	height: 20px;
	border:3px solid #147bb5;
	border-radius: 5px;
	cursor: pointer;
}
/* Styles the text area for the task description */
#taskDescription {
	font-size: 20px;
	font-family: 'Open Sans', sans-serif;
}
.planNameDescription {
	margin-top: 0px;
	margin-left: 30px;
    width: fit-content;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: left;
    color: #aaaaaa;
}
/* Progress Bar in Tasklist */
#planName-ProgressBar-Div {
	display: flex;
}
/* task list progress bar desciption */
#progressBar-description {
	display: flex;
	flex-direction: column;
	text-align: center;
	margin-left: 150px;
}
/* task list progress bar */
#progressBar {
	width: 400px;
	height: 30px;
	-webkit-appearance: none;
	border-radius: .25rem;
	margin-top: 75px;
}
#progressBar::-webkit-progress-value {
	background: #147bb5;
	border-radius: .25rem;
}
#progressBar::-webkit-progress-bar {
	background: #e9ecef;
	border-radius: .25rem;
}
#progressBarPercentage {
	position: relative;
	color: black;
}
/* styles the breadcrumbs throughout the website */
#breadCrumbs {
	font-size: 20px;
	margin: 30px 0 0 30px;
	cursor: pointer;
	width: fit-content;
}
/* styles the icons buttons like the exit popup icon */
.icon_button {
	cursor: pointer;
	filter: invert(17%) sepia(48%) saturate(2009%) hue-rotate(203deg) brightness(98%) contrast(92%);
}
/* Styles the box on the Task List page */
#userStatusBox {
	width: 20px;
	height: 20px;
	border:3px solid #147bb5;
	border-radius: 50%;
}

/* rc list progress bar */
#rcProgressBar1 {
	width: 400px;
	height: 30px;
	-webkit-appearance: none;
	border-radius: .25rem;
}
#rcProgressBar1::-webkit-progress-value {
	background: #078f45;
	border-radius: .25rem;
}
#rcProgressBar1::-webkit-progress-bar {
	background: #e9ecef;
	border-radius: .25rem;
}
#rcProgressBar2 {
	width: 400px;
	height: 30px;
	-webkit-appearance: none;
	border-radius: .25rem;
	margin-top: 20px;
}
#rcProgressBar2::-webkit-progress-value {
	background: #ffc60a;
	border-radius: .25rem;
}
#rcProgressBar2::-webkit-progress-bar {
	background: #e9ecef;
	border-radius: .25rem;
}
#rcProgressBar3 {
	width: 400px;
	height: 30px;
	-webkit-appearance: none;
	border-radius: .25rem;
	margin-top: 20px;
}
#rcProgressBar3::-webkit-progress-value {
	background: #cc3232;
	border-radius: .25rem;
}
#rcProgressBar3::-webkit-progress-bar {
	background: #e9ecef;
	border-radius: .25rem;
}
/* Roll Call User List page style filter select */
#selectFilter {
	margin: auto 0;
	height: 23px;
	width: 110px;
	-webkit-appearance: none;
	text-align: center;
    text-align-last: center;
    -moz-text-align-last: center;
}
/* styles the react select input box makes the box fit */
#searchBox input {
	margin: auto !important;
}
/* the chat box that opens up */
#chatBox {
	border-radius: 10px;
	border: 3px solid #147bb5;
	height: 500px;
	width: 350px;
	position: fixed;
    bottom: 100px;
    margin-right: 100px;
}
/* user input for the new message */
#chatBoxInput {
	border-radius: 20px;
	border-width: 1px;
	position: absolute;
	bottom: 5px;
	left: 5px;
	padding: 3px 10px;
	background: rgba(0, 0, 0, .05);
	width: -webkit-fill-available;
	margin-right: 25px;
	margin-bottom: 0px;
	text-indent: 17px;
}
/* click on element to add image (plus sign) */
#chatBoxSelectImage {
	opacity: 0;
	width: 0;
    height: 0;
}
/* plus sign that is shown in chat box */
#chatBoxPlusSign {
	font-size: 35px;
    position: absolute;
	bottom: 24px;
	width: 20px;
	height: 20px;
	left: 8px;
	cursor: pointer;
	color: #147bb5;
	z-index: 10;
}
/* the box of all the previous messages */
#messagesBox {
	position: absolute;
	left: 8px;
	margin-right: 28px;
    width: -webkit-fill-available;
	border: 1px solid black;
	border-radius: 5px;
	height: 427px;
	overflow: scroll;
}
/* list of messages */
#messageList {
	display: flex;
	flex-direction: column;
	margin-top: 0px;
}
#messageList li {
	margin-bottom: 5px;
	padding: 3px 10px;
	border-radius: 20px;
	max-width: 250px;
	width: fit-content;
  	width: -moz-fit-content;
}
/* send chat button */
#sendChatBtn {
	visibility: hidden;
}
/* current person in the chat */
.currUserMessage {
	background-color: lightblue;
	margin-left: auto;
}
/* The other person in the chat */
.otherUserMessage {
	background: #E5E5EA;
}
/* name of the other user who sent the message */
.otherUserName {
	font-size: 15px;
	color: lightgray;
}
/* name of the user who sent the message */
.currUserName {
	font-size: 15px;
	color: lightgray;
	margin-left: auto;
}
/* the box of all the previous messages */
#convoBox {
	position: absolute;
	bottom: 16px;
	border: 1px solid black;
	border-radius: 5px;
	height: 450px;
	width: 349px;
	overflow: scroll;
}
/* list of messages */
#convoList {
	display: flex;
	flex-direction: column;
	margin-top: 0px;
}
/* each individual message */
#convoList li {
	text-align: center;
	padding: 10px 0;
	border: 1px solid black;
	cursor: pointer;
}
/* Title of a chat between 2 people */
#chatTitle {
	text-align: center;
	margin-bottom: 8px;
}

#filterArrow {
	position: relative;
	top: 3px;
	height: 17px;
	cursor: pointer;
}
/* styles the container that holds the table and the piechart */
#dashboardPage article {
	display: flex;
}
#dashboardTableCont > div > table {
	background: white;
    border-radius: 10px;
}
/* styles the dashboard table */
#dashboardPage table {
	margin: 0 30px;
    width: 100%;
    height: 50%;
    overflow: scroll;
}
/* styles the div that holds the table and the div that holds the pie chart */
#dashboardPage article div {
	width: 50%;
}
/* styles the pie chart and the stats */
#pieChartBox {
	position: relative;
    width: fit-content !important;
    left: 100px;
}
#pieChartStat {
	margin-bottom: 10px;
	background: #22397a;
    font-size: 20px;
	width: fit-content;
	height: fit-content;
	padding: 5px 30px;
	border-radius: 5px;
	color: white;
	text-align: left;
	position: relative;
	left: 100px;
}
#can {
    border: 2px solid #C9C9C9;
	border-radius: 50%;
	height: 300px;
	width: 300px;
}
#admincan {
    border: 2px solid #C9C9C9;
	border-radius: 50%;
	height: 300px;
	width: 300px;
}
#pieTitle {
	font-size: 20px;
	color: #C9C9C9;
	font-weight: bold;
	margin-top: 0 !important;
}

/* styles the pie chart and the stats */
#pieChartBoxSuperAdmin {
	flex-direction: column;
	margin-left: 30px;
}

/* unread messages */
.dot {
	height: 10px;
    width: 10px;
    background-color: red;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
}

/* Root admin select client to filter userlist  */
#selectClientUserTable {
    font-size: 15px;
    height: 40px;
    color:gray;
    position: absolute;
    margin-top: 25px;
    padding-left: 10px;
    border: 1px solid lightgrey;
    border-radius: 5px;
    max-width: 260px;


}

.tooltip{
	width:100px;
	height:100px;
}

#dashboardPage article div {
    width: 95%;

}
#dashboardPage table {
    margin: 0 30px;
    width: 98%;
    height: 50%;
    overflow: scroll;
}

.formsType{
	padding: 2%;
    border: 1px solid rgb(34 62 133 / 20%);
    width: 95%;
    margin-left: 30px;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    transition: 0.3s;
    border-radius: 5px;
    margin-bottom: 1%;
}
.formTable{
	border-top: 1px solid lightgrey;
    box-shadow: inset 1px -1px 8px 0 rgb(0 0 0 / 20%);
    transition: 0.3s;
    border-radius: 5px;
    margin-left: 30px;
}

.pro-sidebar{
	width: 170px;
	min-width: 170px;
	margin-top: 5%;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
    position: absolute;
    padding-top: 80%;
    transform: translateY(-50%);
}
.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper{
	background: #ffffff !important;
}

.pro-sidebar > .pro-sidebar-inner {
    background: #ffffff !important;
    font-family: Open Sans,sans-serif;
}
.pro-sidebar .pro-menu {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item{
	padding:  0px !important;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item{
	background-color: #ffffff !important;
}
.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper {
    position: absolute;
    left: 10px;
    right: 0px;
    transform: translateY(80%);
    margin-top: 25px;
 }
 .pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
    max-height: 100vh;
    overflow-y: auto;
    background-color: #22397a !important;
    padding-left: 20px;
    border-radius: 4px;
    color: #ffffff !important;
}
.pro-sidebar .pro-menu .pro-menu-item {
    font-size: 20px !important;
}

/* $submenu-bg-color-collapsed: #ffffff !default; */
/* $sidebar-bg-color: #1d1d1d !default;
$sidebar-color: #adadad !default;
$sidebar-width: 270px !default;
$sidebar-collapsed-width: 80px !default;
$highlight-color: #d8d8d8 !default;
$submenu-bg-color: #2b2b2b !default;
$submenu-bg-color-collapsed: #2b2b2b !default;
$icon-bg-color: #2b2b2b !default;
$icon-size: 35px !default;
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default; */
