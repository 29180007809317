/* using this file to style App.js */
@import url('https://fonts.googleapis.com/css?family=Open+Sans&display=swap');
/* styling the body */
html, body {
    margin: 0;
	padding: 0;
    font-family: 'Open Sans', sans-serif;
}
/* makes the main menu and the Userpage horizontally aligned */
#root {
    display: flex;
}
/* makes the background of the user list white */
#root > main {
    background: #f1f4f6;
    flex: 1;
    border: solid 1px #f1f4f6;
}
/* styles the section that holds the user list */
#root > main > section {
    border: solid 1px #dddddd;
    padding-bottom: 2%;
}
/* styles the div that contains the table */
#root > main > div {
    display: flex;
}
/* styles the table */
#root > main > div > table {
    flex: 1;
    margin: 0 30px;
    background: white;
    border-radius: 0 0 10px 10px;
}

/* styling the main admin console naviation on the left*/
#mainNav {
    position: sticky;
    position: -webkit-sticky;
    top: 0; /* required */
    padding-top: 100px;
    height: 100vh;
    /* width: 300px; */
    text-align: left;
    display: block;
    list-style-type: none;
    margin: 0;
    background: white;
}
/* styles the main navigation item */
.mainItem {
    position: relative;
    /* color: #22397a; */
    color: #bdc6dd;
    padding: 10px 0 10px 25px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.35;
    cursor: pointer;
}
/* styles the selected item of the main navigation */
.selectedItem {
    /* background: #036aa4; */
    color: #22397a
}
/* selected items icon (main left side bar) */
.selectedItem > img {
    filter: invert(17%) sepia(48%) saturate(2009%) hue-rotate(203deg) brightness(98%) contrast(92%) !important;
}

#logoutButton {
    position: fixed;
    bottom: 50px;
    color: #22397a;
}
#logoutButton > img {
    filter: invert(17%) sepia(48%) saturate(2009%) hue-rotate(203deg) brightness(98%) contrast(92%) !important;
}
/* styles the icons in the main navigation */
.mainItem > img {
    margin-bottom: -6px;
    filter: invert(88%) sepia(10%) saturate(518%) hue-rotate(189deg) brightness(88%) contrast(97%);
    /* filter: invert(17%) sepia(48%) saturate(2009%) hue-rotate(203deg) brightness(98%) contrast(92%); */
}
/* styles the li tag that contains the backArrow */
#backArrow {
    position: absolute;
    top: 27px;
    left: 24px;
    cursor: pointer;
    z-index: 2;
}

#toggleMenuArrow {
    padding: 5px;
    position: fixed;
    top: 50px;
	border-radius: 50%;
    background: grey;
    z-index: 2;
    cursor: pointer;
    border: 3px solid white;
}
#signInBackGround {
    width: 100%;
    height: 100%;
    background: #403F44;
    position: fixed;
}
#signInBorder {
    border: solid 1px rgba(29, 29, 29, 0.25);
    border-radius: 3px;
    box-shadow: 3px 5px 10px 0 rgba(0, 0, 0, 0.15);
    margin: 150px auto;
    width: 445px;
    height: 470px;
}
#chatMessagesIcon {
    position: fixed;
    right: 50px;
    bottom: 50px;
    height: 60px;
    cursor: pointer;
}

#chatBackArrow {
    position: absolute;
    left: 8px;
    background: grey;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
    height: 24px;
    padding: 2px;
}
.forgot_pass{
    color:white;
}
.forgot_pass:hover{
    color:white;
    text-decoration: underline;
    cursor: pointer;
}
.hidden{
    display: none;
}
/* every even column has a light grey background */
tbody > tr:nth-child(even) {
    background: #f7f8f9;
}
.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul{
  display: block
}
