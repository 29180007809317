


/* styling the login page */
.signIn {
    max-width: 500px;
    max-height: 500px;
    padding: 20px;
    margin-top: 5%;
    text-align: center;
    border-style: solid;
    border-width: 25px;
    border-radius: 5px;
    border-color: rgb(218,185,61);
    background: white;
}
.logo {
    margin: 20px 0;
}

.lgnTitle {
    font-size: 18px;
    margin: auto;
    margin-bottom: 20px;
    font-weight: bold;
}
/* the div around the input */
.inputbox {
    text-align: center;
}
/* the actual input tag */
.logInput {
    padding: 5px;
    width: 300px;
    border: 1px solid rgb(224, 228, 232);
    margin-bottom: 5px;
}
.ltag {
    font-size: 20px;
}
.signInBtn {
    width: 140px;
    margin: 0;
    padding: 1em 2em;
    color: #fff;
    background: rgb(218,185,61);
    border: 0;
    border-radius: 10px;
    font-size: 15px;
}
